<template>
    <div class="evaluateContent">
        <div class="header">
            <i class="el-icon-alifanhui" @click="back"></i>
            <div style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden">{{title}}</div>
            <i></i>
        </div>
        <div class="boxMain">
            <div class="mainHeader">
                <div class="headerLeft">当前得分：{{currScore}}</div>
                <div class="headerRight">
                    <div>
                        <i class="el-icon-alishijian"></i>
                        <span>{{getHMS(time)}}</span>
                    </div>
                </div>
            </div>
            <div class="mainContent">
                <ul class="sortList">
                    <li v-for="(item,index) in dataList" :key="index">
                        <span style="color:#ccc;font-weight:bold">{{item.Item1}}</span>
                        <div class="quesMain">
                            <p>{{item.Title}}</p>
                            <div class="scoreBox">
                                <van-slider v-model="item.Score" :min="item.MinScore" :max="item.MaxScore" :step="item.MaxScore/5" @change="numChange" />
                                <van-stepper v-model="item.Score" :min="item.MinScore" :max="item.MaxScore" :step="item.MaxScore/5" @change="numChange" />
                            </div>
                        </div>
                    </li>
                    <li>
                        <span style="color:#ccc;font-weight:bold">总结</span>
                        <van-field v-model="message" type="textarea" placeholder="请输入评语" rows="4" autosize />
                    </li>
                </ul>
                <div class="completeBtn" @click="complete">完成评价</div>
            </div>
        </div>

        <!-- 弹出框内容 -->
        <van-dialog v-model="show" :title="title" show-cancel-button confirmButtonText="提交" @confirm="dialogConfirm" @cancel="dialogCancel">
            <div class="dialogMain">
                <p>最终成绩： <span style="font-size:16px;font-weight:bold;color:#74D798">{{currScore}}/{{allScroe}}</span></p>
                <p>操作用时：{{getHMS(time)}}</p>
            </div>
        </van-dialog>

    </div>
</template>

<script>
    import { Notify } from 'vant';
    import Cookies from 'js-cookie';
    export default {
        data() {
            return {
                dataList: [],
                message: "", // 总结
                show: false,
                type: "", // 表类型
                evaluateID: "",
                title: "", // 表名
                allScroe: 0, // 总分
                currScore: 0, // 当前得分
                StudentGroupID: "",//分组ID
                studentID: "",
                time: 0,
                timer: null,
            }
        },
        methods: {
            // 获取数据
            initData() {
                this.$axios.get('/api/Evaluate/EvaluateInfo?evaluateID=' + this.evaluateID, "").then(res => {
                    var data = JSON.parse(res.data);
                    console.log(data);
                    if (data.code == 200) {
                        this.type = data.data.Type;
                        this.title = data.data.Title;
                        this.dataList = data.data.EvaluateItems;
                        this.dataList.forEach(v => {
                            this.allScroe += v.Score;
                            this.currScore += v.Score;
                        });
                    }
                });
            },
            // 点击头部左上角返回
            back() {
                this.$router.push({ name: "evaluateTable" });
            },
            // 点击步进器加减按钮
            numChange() {
                this.currScore = 0;
                this.dataList.forEach(v => {
                    this.currScore += v.Score;
                });
            },
            // 点击完成按钮
            complete() {
                this.show = true;
                clearInterval(this.timer);
            },
            // 点击弹框确认按钮
            dialogConfirm() {
                let newArr = [];
                this.dataList.forEach(v => {
                    let newObj = {
                        EvaluationItemID: v.ID,
                        Answer: v.Score,
                        MaxScore: v.MaxScore,
                    }
                    newArr.push(newObj);
                });

                let para = {
                    Type: this.type,
                    EvaluateID: this.evaluateID,
                    UserID: this.studentID,
                    EvaluateTitle: this.title,
                    Remark: this.message,
                    CompletionTime: this.time,
                    StudentGroupID: this.StudentGroupID,
                    EvaluateItemsRequestList: newArr,
                    UserType: "Student",
                };
                console.log(para);
                this.$axios.post('/api/Evaluate/InsertEvaluateRecord', para).then(res => {
                    var data = JSON.parse(res.data);
                    console.log(data);
                    if (data.code) {
                        Notify({
                            message: '提交成功', duration: 1000,
                            background: '#2ACC9B'
                        });
                        this.$router.push({ name: "evaluateStudy" });
                    }
                });

            },
            // 点击弹框取消按钮
            dialogCancel() {
                this.timer = setInterval(() => {
                    this.time++;
                }, 1000);
                this.initData();
            },
            // 转换秒数
            getHMS(value) {
                var secondTime = parseInt(value); // 秒
                var minuteTime = 0; // 分
                var hourTime = 0; // 小时
                if (secondTime >= 60) {
                    minuteTime = parseInt(secondTime / 60);
                    secondTime = parseInt(secondTime % 60);
                    if (minuteTime >= 60) {
                        hourTime = parseInt(minuteTime / 60);
                        minuteTime = parseInt(minuteTime % 60);
                    }
                }
                var result = "" + (parseInt(secondTime) < 10 ? "0" + parseInt(secondTime) : parseInt(secondTime)) + "秒";
                var result2 = "" + (parseInt(minuteTime) < 10 ? "0" + parseInt(minuteTime) : parseInt(minuteTime)) + "分" + result;
                var result3 = "" + (parseInt(hourTime) < 10 ? "0" + parseInt(hourTime) : parseInt(hourTime)) + "时" + result2;
                if (parseInt(hourTime) == 0) {
                    return result2;
                } else {
                    return result3;
                }
            },
        },
        mounted() {
            this.evaluateID = this.cookies.kkGet("evaluateID");
            this.studentID = Cookies.get('studentID');
            this.StudentGroupID = this.cookies.kkGet("StudentGroupID");
            this.timer = setInterval(() => {
                this.time++;
            }, 1000);
            this.initData();
        },
        destroyed() {
            this.time = 0;
            clearInterval(this.timer);
        }
    }
</script>

<style>
    .evaluateContent {
        width: 100%;
        position: relative;
    }

        .evaluateContent .header {
            width: 100%;
            height: 44px;
            line-height: 44px;
            text-align: center;
            color: #101010;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: fixed;
            top: 0;
            left: 0;
            background: #f8f8f8;
            z-index: 999;
        }

            .evaluateContent .header i {
                margin: 0 18px;
                font-size: 20px;
                display: block;
                width: 30px;
            }

        .evaluateContent .boxMain {
            margin-top: 44px;
        }

            /* 头部当前得分样式 */
            .evaluateContent .boxMain .mainHeader {
                width: 100%;
                height: 40px;
                line-height: 40px;
                padding: 0 20px;
                background: #eafbf8;
                display: flex;
                justify-content: space-between;
                position: fixed;
                top: 44px;
                left: 0;
                z-index: 999;
            }

                .evaluateContent .boxMain .mainHeader .headerRight {
                    display: flex;
                }

                    .evaluateContent .boxMain .mainHeader .headerRight .timeTitle {
                        margin: 0 5px 0 10px;
                    }

        /* 中间内容样式 */
        .evaluateContent .mainContent {
            padding: 0 20px 20px;
            min-height: calc(100vh - 40px - 44px);
            margin-top: 90px;
        }

        .evaluateContent .sortList {
            margin-top: 20px;
        }

            .evaluateContent .sortList li {
                margin-top: 10px;
            }

        .evaluateContent .mainContent .sortList li .van-cell {
            padding: 0;
            margin-top: 20px;
            border: 1px solid #ccc;
            border-radius: 4px;
        }

        .evaluateContent .mainContent .sortList li .van-field__control {
            padding: 10px;
        }

        .evaluateContent .van-dialog__confirm {
            color: #3894ff;
        }

        .evaluateContent .mainContent .sortList li .quesMain {
            padding: 20px;
            border: 1px dashed #ccc;
            margin-top: 10px;
        }

            .evaluateContent .mainContent .sortList li .quesMain .scoreBox {
                display: flex;
                justify-content: space-between;
                margin-top: 20px;
            }

                .evaluateContent .mainContent .sortList li .quesMain .scoreBox .van-slider {
                    width: 55%;
                    margin-top: 10px;
                }

        .evaluateContent .sortList li .quesMain .scoreBox .van-slider__button {
            width: 16px;
            height: 16px;
        }

        .evaluateContent .completeBtn {
            width: 80%;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: #3294ff;
            margin: 20px 10% 0;
            color: #fff;
            letter-spacing: 3px;
        }

        .evaluateContent .dialogMain {
            width: 100%;
            text-align: center;
            padding: 20px 0;
        }

            .evaluateContent .dialogMain p {
                margin-top: 8px;
            }

        .evaluateContent .van-dialog__header {
            font-size: 16px;
            font-weight: bold;
        }
</style>